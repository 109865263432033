import React from "react";

import HeaderView from "./components/header";
import { Spin } from "antd";
import moment from "moment";
const IconFont = Loader.loadBaseComponent("IconFont");
const NoData = Loader.loadBaseComponent("NoData");
const ImageMovieMap = Loader.loadBusinessComponent("ImageMovieMap");
const Wrapper = Loader.loadBusinessComponent("BaseLibDetails", "Wrapper");
const CaptureCard = Loader.loadBusinessComponent("Card", "CaptureCard");

const PageDetails = Loader.loadBusinessComponent("PageDetails");
const limit = 6;

export default class faceLibraryDetail extends React.Component {
  constructor(props) {
    super(props);
    // this.init = true
    this.state = {
      searchData: {},
      loading: true,
      //默认选中id
      activeId: "",
      //上一页数据
      preList: [],
      //当前页数据
      nowList: [],
      //下一页数据
      nextList: []
    };
  }
  componentWillMount() {
    try {
      this.initRequest();
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  initRequest = async () => {
    const { location } = this.props;
    const { id } = Utils.queryFormat(location.search);
    const result = await window.LM_DB.get("parameter", id);
    if (!result) {
      return;
    }
    this.setState({
      searchData: result.searchData,
      activeId: result.id,
      nowList: [result.data],
      loading: false
    });
    const res = await this.queryFaces({ minId: result.id, pageType: 2, limit: 5 });
    this.getlog(result.data);
    const list = await Promise.all([
      this.queryFaces({ minId: res[res.length - 1].id, currentPage: 2, pageType: 2, limit }),
      this.queryFaces({ maxId: result.id, limit, pageType: 1 })
    ]);
    this.setState({
      nowList: [result.data].concat(res),
      preList: list[1],
      nextList: list[0]
    });
  };

  /**
   * @desc 查看图库详情日志
   * @param {string} 当前选中图片id
   * @param {object} 当前展示数据
   */
  getlog(data) {
    let infoList = Service.url.getLogInfoList();
    const params = infoList.find(v => v.moduleName === "faceDetailModule");
    let logInfo = {
      ...params,
      description: `查看点位【${data.deviceName}】 ${moment(+data.captureTime).format("YYYY.MM.DD HH:mm:ss")}的人脸抓拍照片`
    };
    Service.logger.save(logInfo);
  }

  changePageId = id => {
    const { preList, nowList, nextList, searchData } = this.state;
    const allData = preList.concat(nowList).concat(nextList);
    const data = allData.find(v => v.id === id) || {};
    this.getlog(data);
    LM_DB.add("parameter", {
      id,
      data,
      searchData
    }).then(() => {
      BaseStore.tab.goPage({
        moduleName: "faceLibraryDetail",
        active: "replace",
        isUpdate: true,
        data: { id }
      });
    });
  };

  /**
   * @desc 获取上一页数据
   */
  getPreData = activeId => {
    // this.setState({ loading: true })
    const { preList, nowList } = this.state;
    this.queryFaces({
      maxId: preList[0].id,
      pageType: 1,
      limit
    }).then(list => {
      this.setState({
        nextList: nowList,
        nowList: preList,
        preList: list,
        activeId: activeId || preList[0].id
        // loading: false
      });
      this.changePageId(activeId || preList[0].id);
    });
  };

  /**
   * @desc 翻到下一页数据
   * @param {String} type pre: 上一页 next: 下一页
   */
  getNextData = () => {
    // this.setState({ loading: true })
    const { nextList, nowList } = this.state;
    this.queryFaces({
      minId: nextList[nextList.length - 1].id,
      pageType: 2,
      limit
    }).then(list => {
      this.setState({
        nextList: list,
        nowList: nextList,
        preList: nowList,
        activeId: nextList[0].id
        // loading: false
      });
      this.changePageId(nextList[0].id);
    });
  };

  /**
   * @desc 点击切换
   */
  changeActiveId = (activeId, type) => {
    this.changePageId(activeId);
    if (!this.state.nowList.find(v => v.id === activeId)) {
      if (type === "pre") {
        this.getPreData(activeId);
      }
      if (type === "next") {
        this.getNextData();
      }
    } else {
      this.setState({ activeId });
    }
  };

  /**
   * @desc 列表查询
   * @param {Object} options
   */
  queryFaces = (options = {}) => {
    let searchData = Object.assign({}, this.state.searchData);
    if (options.pageType === 1) {
      searchData.minId = undefined;
    }
    return Service.face.queryFaces({ ...searchData, ...options }).then(res => {
      return res.data.list;
    });
  };

  renderContent() {
    const { loading, activeId, nowList, preList, nextList } = this.state;
    if (loading) {
      // this.init = false
      return null;
    }

    if (!!!nowList.length) {
      return <NoData />;
    }
    const allData = preList.concat(nowList).concat(nextList);
    console.log(allData);
    const index = allData.findIndex(v => v.id === activeId);
    const data = allData[index] || allData[0];
    //所有列表数据

    //上一条数据
    const preData = allData[index - 1];
    //下一条第一条数据
    const nextData = allData[index + 1];
    return (
      <React.Fragment>
        <HeaderView {...data} />
        <div className="picture-container">
          {preData && (
            <div className="nav-l">
              <PageDetails imgUrl={preData.faceUrl} onChange={this.changeActiveId} id={preData.id} pageType="pre" />
            </div>
          )}
          {nextData && (
            <div className="nav-r">
              <PageDetails imgUrl={nextData.faceUrl} id={nextData.id} onChange={this.changeActiveId} />
            </div>
          )}
          <ImageMovieMap type="face" data={data} key={data.id} searchData={this.state.searchData} />
        </div>
        <div className="footer-list-container">
          {!!preList.length && (
            <div className="cg l" onClick={() => this.getPreData()}>
              <IconFont type="icon-S_Arrow_BigLeft" />
            </div>
          )}
          {!!nextList.length && (
            <div className="cg r" onClick={() => this.getNextData()}>
              <IconFont type="icon-S_Arrow_BigRight" />
            </div>
          )}
          <div className={`detail-list-item ${nowList.length !== 6 ? "less" : ""}`}>
            {nowList.map(v => (
              <CaptureCard
                className="detail-box-item"
                relativeIcon=""
                hoverScale={false}
                deviceName={v.deviceName}
                active={activeId === v.id ? true : false}
                captureTime={v.captureTime}
                onClick={() => this.changeActiveId(v.id)}
                imgUrl={v.faceUrl}
              />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <Wrapper>
        <Spin spinning={loading}>
          <div style={{ width: "100%", height: "100%", minHeight: 400 }}>{this.renderContent()}</div>
        </Spin>
      </Wrapper>
    );
  }
}
